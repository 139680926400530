import React, { Component, useEffect, useState } from "react";

import { Button, Divider, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import MDBox from "components/MDBox";
import { HomeMiniOutlined } from "@mui/icons-material";
import { PiHouse, PiHouseBold, PiHouseLine } from "react-icons/pi";
import { useParams, useSearchParams } from "react-router-dom";
import { getArtById } from "actions";
import { HOST_URL } from "constants";
import { toast } from "react-toastify";
import MDButton from "components/MDButton";
import { FaDownload } from "react-icons/fa";
import { getHighResImagePurchase } from "actions/UserAction";
import { download } from "utils";
import { ASSETS_URL } from "constants";

function PurchaseSuccessful() {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  let [piece, setPiece] = useState({
    imageUrl: "",
    title: "",
    credits: "Siyavush Mammad",
    description: "",
    categories: [],
    likesCount: 0,
    starsCount: 0,
    commentsCount: 0,
    viewsCount: 0,
    downloadsCount: 0,
    liked: false,
    starred: false,
    lowRes: {
      width: 512,
      height: 512,
    },
    highRes: {
      width: 512,
      height: 512,
    },
  });
  let user = useSelector((state) => state.navigationReducer.user);
  let bearerToken = useSelector((state) => state.navigationReducer.bearerToken);

  useEffect(() => {
    getArtById(id, bearerToken)
      .then((response) => {
        console.log(response.data);

        let entity = response.data;
        let data = {
          imageUrl: `${ASSETS_URL}/${entity.imageCompressedLocation}`,
          imageName: entity.imageCompressedLocation,
          imageNameHighRes: entity.imageLocation,
          title: entity.name,
          credits: "Siyavush Mammad",
          description: entity.description,
          categories: entity.categories,
          likesCount: entity.likes,
          commentsCount: entity.comments,
          viewsCount: entity.views,
          downloadsCount: entity.downloads,
          liked: entity.liked,
          starred: entity.saved,
          lowRes: entity.lowRes,
          highRes: entity.highRes,
          price: entity.price,
        };

        setPiece(data);
      })
      .catch((error) => {
        toast("Something went wrong!");
      });
  }, []);

  let downloadImage = () => {
    getHighResImagePurchase(
      bearerToken,
      piece.imageNameHighRes,
      searchParams.get("payment_intent_client_secret")
    )
      .then((response) => {
        download(
          response.data,
          piece.title + "." + piece.imageNameHighRes.split(".")[1]
        );
      })
      .catch((error) => {
        console.log("error: ", error.response.data.errorKey);

        switch (error.response.data.errorKey) {
          case "payment-processing":
            toast(
              "Payment is still processing, please click again in a few seconds!"
            );
            break;
          case "payment-cancelled":
            toast("Payment was cancelled, please try again!");
            break;
          default:
            toast("Something went wrong, please try again!");
            break;
        }
      });
  };

  return (
    <Grid container style={{ width: "100%" }} pt={10}>
      <Grid container style={{ display: "flex", flexDirection: "row" }}>
        <Grid
          item
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <img
            src={`${piece.imageUrl}`}
            style={{
              maxWidth: "512px",
              maxHeight: "512px",
              borderRadius: "10px",
            }}
            srcSet={`${piece.imageUrl}`}
            alt={""}
            loading="lazy"
          />
        </Grid>
        <Grid
          item
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <Typography variant="h3">{`${piece.title} Purchase`}</Typography>
          <Typography variant="overline">{`Successful Payment`}</Typography>

          <Divider variant="middle" style={{ width: "100%" }} />

          <Typography variant="overline">
            {`We have sent your purchase receipt on `}{" "}
            <a href={`mailto:${user.email}`}>{`${user.email}`}</a>
          </Typography>

          <Grid
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
            p={10}
          >
            <Button
              startIcon={<FaDownload />}
              style={{ color: "white" }}
              variant="contained"
              onClick={() => {
                downloadImage();
              }}
            >
              Download Image
            </Button>
          </Grid>

          <MDBox
            mt={20}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              width: "100%",
            }}
            onClick={() => {
              window.location.href = "/home";
            }}
          >
            <PiHouseBold style={{ marginRight: 10 }} size={20} />
            <Typography
              variant="overline"
              style={{ fontWeight: "bold", textDecoration: "underline" }}
            >
              {" "}
              Back to Home
            </Typography>
          </MDBox>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PurchaseSuccessful;
