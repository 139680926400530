import { HOST_URL, SIGN_IN, USER } from "../constants";
import axios, { AxiosError } from "axios";
import { makeHeaders } from "../utils";
import { toast } from "react-toastify";
import { store } from "store";

export const authenticateUser = (login, password, rememberMe) => {
  return axios.post(
    `${HOST_URL}/api/authenticate`,
    {
      username: login,
      password,
      rememberMe,
    },
    {}
  );
};

export const registerUser = (login, name, surname, password, email) => {
  return axios.post(`${HOST_URL}/api/register`, {
    login,
    password,
    firstName: name,
    lastName: surname,
    email,
  });
};

export const changePassword = (currentPassword, newPassword, bearerToken) => {
  return axios.post(
    `${HOST_URL}/api/account/change-password`,
    {
      currentPassword,
      newPassword,
    },
    {
      headers: makeHeaders(bearerToken),
    }
  );
};

export const updateUser = (login, email, bearerToken) => {
  return axios.put(
    `${HOST_URL}/api/users`,
    {
      login,
      email,
    },
    {
      headers: makeHeaders(bearerToken),
    }
  );
};

export const recoverPassword = (email, bearerToken) => {
  let data = {
    email: email,
  };

  return axios.post(`${HOST_URL}/api/user/forgot-password`, data, {
    headers: makeHeaders(bearerToken),
  });
};

export const getUserAccountData = (bearerToken) => {
  let header = makeHeaders(bearerToken);

  return axios.get(`${HOST_URL}/api/account`, { headers: header });
};

export const getHighResImage = (bearerToken, imageName) => {
  let header = makeHeaders(bearerToken);
  let ipAddress = store.getState().navigationReducer.ipAddress;

  return axios.get(
    `${HOST_URL}/api/arts/image/${imageName}/download?compressed=false&ipAddress=${ipAddress}`,
    { headers: header }
  );
};

export const activateAccount = (key) => {
  let header = makeHeaders();

  return axios.get(`${HOST_URL}/api/activate?key=${key}`, {
    headers: header,
  });
};

export const getHighResImagePurchase = (
  bearerToken,
  imageName,
  clientSecret
) => {
  let header = makeHeaders(bearerToken);
  let ipAddress = store.getState().navigationReducer.ipAddress;

  return axios.get(
    `${HOST_URL}/api/arts/image/${imageName}/download-purchase?clientSecret=${clientSecret}&ipAddress=${ipAddress}`,
    { headers: header }
  );
};

export const singleSignOn = async (type, dispatch) => {
  const redirectUrl = `https://smaiart.com/sso/${type}`;

  switch (type) {
    case "google": {
      // https://developers.google.com/identity/protocols/oauth2/javascript-implicit-flow
      window.open(
        `https://accounts.google.com/o/oauth2/v2/auth?scope=email&include_granted_scopes=true&response_type=token&state=state_parameter_passthrough_value&client_id=668114787970-2lagl14q5ccu3n81n6j82ggl6055lh6l.apps.googleusercontent.com&redirect_uri=${encodeURIComponent(
          redirectUrl
        )}`,
        "_self"
      );
      return;
    }

    case "discord": {
      window.open(
        `https://discord.com/api/oauth2/authorize?client_id=1159420679930773545&redirect_uri=${encodeURIComponent(
          redirectUrl
        )}&response_type=code&scope=identify`,
        "_self"
      );
      return;
    }

    case "telegram": {
      // https://core.telegram.org/widgets/login
      // https://stackoverflow.com/questions/56347902/telegram-authorization-without-default-button/63593384#63593384
      window.Telegram.Login.auth(
        { bot_id: "6669237784", request_access: true },
        (data) => {
          if (!data) {
            console.log("telegram authentication failed");
            return;
          }

          const load = async () => {
            try {
              axios
                .post(`${HOST_URL}/api/oauth/${type}`, {
                  user: data,
                })

                .then((response) => {
                  const token = response.data.id_token;

                  dispatch({
                    type: SIGN_IN,
                    payload: token,
                  });

                  console.log(token);
                  getUserAccountData(token)
                    .then(function (response) {
                      if (response.error) {
                        toast("Something went wrong!");
                      }

                      console.log(response.data);

                      dispatch({
                        type: USER,
                        payload: response.data,
                      });
                      return response.data.id_token;
                    })
                    .finally((e) => {
                      window.location.href = "/home";
                    })
                    .catch(function (error) {
                      console.log(error);
                      toast(error.response.data.title);
                    });
                });

              // await singleSignOnLoginWithToken(
              //   authenticateResponse.data.id_token
              // );
            } catch (e) {
              if (e instanceof AxiosError) {
                window.location.href = `/profile/settings?errorCode=${getErrorCodeFromError(
                  e
                )}`;
              }
            }
          };

          load();
        }
      );
      return;
    }

    case "twitter": {
      const clientId = "VC1Rb1pSem5lSi1xdmxGMTljc1g6MTpjaQ";
      const codeChallenge = `challenge`;

      window.open(
        `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${encodeURIComponent(
          clientId
        )}&redirect_uri=${encodeURIComponent(
          redirectUrl
        )}&scope=tweet.read%20users.read%20offline.access&state=state&code_challenge=${codeChallenge}&code_challenge_method=plain`,
        "_self"
      );
      return;
    }
  }
};

export const singleSignOnAuthorise = async (type, locationParams) => {
  let body = undefined;

  switch (type) {
    case "twitter": {
      body = {
        authorizationCode: locationParams.get("code"),
      };
      break;
    }

    case "discord": {
      body = {
        authorizationCode: locationParams.get("code"),
      };
      break;
    }

    case "google": {
      const values = window.location.href.split("#")[1];
      const split_values = values.split("&");
      const access_token_full = split_values.filter((value) =>
        value.includes("access_token")
      )[0];
      const accessToken = access_token_full.replace("access_token=", "");

      const userInfoResponse = await axios.get(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${accessToken}`
      );
      const { email } = userInfoResponse.data;

      body = {
        email,
        authorizationCode: accessToken,
      };
      break;
    }
  }

  if (!body) {
    console.log("no request body defined");
    return;
  }

  return axios.post(`${HOST_URL}${`/api/oauth/${type}`}`, body);
};

const getErrorCodeFromError = (e) => {
  let errorCode = "";

  if (e.response?.data.errorKey === "user-exists") {
    errorCode = "ssoUserExists";
  } else {
    errorCode = "ssoUnknown";
  }

  return errorCode;
};
