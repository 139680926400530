import React from "react";
import { FaFacebookF, FaTelegram, FaTwitter } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  authenticateUser,
  getUserAccountData,
  getUserAccountTypeInfo,
  getUserInfo,
  singleSignOn,
} from "../../actions/UserAction";
import { Link } from "react-router-dom";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Divider, Grid, Switch } from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { SIGN_IN, SET_AUTH } from "constants";
import { USER } from "constants";
import { jwtDecode } from "jwt-decode";

const SignIn = ({ onSignup, onComplete }) => {
  let [container, setContainer] = React.useState("container");
  let dispatch = useDispatch();

  let [email, setEmail] = React.useState("");
  let [password, setPassword] = React.useState("");
  let [remember, setRemember] = React.useState(false);

  const handleSingleSignOn = async (type) => {
    try {
      await singleSignOn(type, dispatch);
    } catch (e) {
      if (e instanceof Error) {
        toast(e.message);
      }
    }
  };

  const signIn = () => {
    authenticateUser(email, password, remember)
      .then(function (response) {
        if (response.error) {
          toast("Wrong Email/Password");
        }
        return response.data.id_token;
      })
      .then((token) => {
        dispatch({
          type: SIGN_IN,
          payload: token,
        });

        const user = jwtDecode(token);

        dispatch({
          type: SET_AUTH,
          payload: user.auth,
        });

        return token;
      })
      .then((token) => {
        getUserAccountData(token)
          .then(function (response) {
            if (response.error) {
              toast("Wrong Email/Password");
            }

            dispatch({
              type: USER,
              payload: response.data,
            });
            return response.data.id_token;
          })
          .catch(function (error) {
            console.log(error);
            toast(error.response.data.title);
          });

        onComplete();
      })
      .catch(function (error) {
        console.log(error);
        toast(error.response.data.title);
      });
  };

  const notify = (text) => toast(text);
  const handleSetRememberMe = () => setRemember(!remember);

  return (
    <div>
      <div className="sign-in-container">
        <div className={"container"} id="container">
          <div className="form-container sign-in-container">
            <MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                mb={5}
              >
                <MDTypography variant="h3">Welcome</MDTypography>
              </MDBox>
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                columnGap={5}
              >
                <Grid
                  item
                  onClick={() => {
                    handleSingleSignOn("telegram", dispatch);
                  }}
                >
                  <MDBox
                    className="shadow"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      backgroundColor: "#fff",
                      padding: "5px",
                      cursor: "pointer",
                      borderRadius: "50px",
                    }}
                  >
                    <FaTelegram size={20} color="#3b5998" />
                  </MDBox>
                </Grid>
                <Grid
                  item
                  onClick={() => {
                    handleSingleSignOn("google");
                  }}
                >
                  <MDBox
                    className="shadow"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      backgroundColor: "#fff",
                      padding: "5px",
                      cursor: "pointer",
                      borderRadius: "50px",
                    }}
                  >
                    <FcGoogle size={20} />
                  </MDBox>
                </Grid>
                <Grid
                  item
                  onClick={() => {
                    handleSingleSignOn("twitter");
                  }}
                >
                  <MDBox
                    className="shadow"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      backgroundColor: "#fff",
                      padding: "5px",
                      cursor: "pointer",

                      borderRadius: "50px",
                    }}
                  >
                    <FaTwitter size={20} color="#00ACEE" />
                  </MDBox>
                </Grid>
              </Grid>
              <Divider />

              <MDBox pt={4} pb={3}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <MDInput
                      type="email"
                      label="Email"
                      fullWidth
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="password"
                      label="Password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox display="flex" alignItems="center" ml={-1}>
                    <Switch checked={remember} onChange={handleSetRememberMe} />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleSetRememberMe}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Remember me
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={signIn}
                      fullWidth
                    >
                      sign in
                    </MDButton>
                  </MDBox>
                  <Divider />
                  <MDBox mt={2} mb={1}>
                    <MDButton
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        onSignup();
                      }}
                    >
                      sign up
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MDTypography
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  variant="overline"
                >
                  Forgot Password?
                </MDTypography>
              </MDBox>
            </MDBox>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
