import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { styled, alpha, createTheme } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { MdMenu } from "react-icons/md";
import {
  PiSignOut,
  PiDownloadSimple,
  PiCoffee,
  PiUserCircle,
  PiUserBold,
  PiStar,
  PiHeart,
  PiCrownDuotone,
  PiCrown,
} from "react-icons/pi";
import NotificationItem from "components/Items/NotificationItem";
import {
  Dialog,
  Divider,
  Fab,
  Grid,
  setRef,
  useMediaQuery,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import SignIn from "layouts/sign-in";
import MDBox from "components/MDBox";
import SignUp from "layouts/sign-up";
import { useDispatch, useSelector } from "react-redux";
import { SIGN_IN } from "constants";
import { toast } from "react-toastify";
import { getAllArts } from "actions";
import Logo from "../../assets/logo.svg";
import { HOST_URL } from "constants";
import { parseDate } from "utils";
import axios from "axios";
import { SET_IP } from "constants";
import { ASSETS_URL } from "constants";
import { SET_AUTH } from "constants";

const pages = [
  { label: "Home", src: "/home" },
  { label: "Merch", src: "/merch" },
  { label: "Pricing", src: "/subscriptions" },
  { label: "About", src: "/about" },
  { label: "FAQ", src: "/faq" },
];

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: "#ededed",
  },
  backgroundColor: "#f2f2f2",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",

  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    fontSize: "16px",
    width: "auto",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const themeWidth = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

function ResponsiveAppBar() {
  const dispatch = useDispatch();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openSubscription, setOpenSubscription] = React.useState(false);
  const [signUpOpen, setSignupOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let handleSignUpOpen = () => setSignupOpen(true);
  const handleSignUpClose = () => setSignupOpen(false);
  const [itemData, setItemData] = React.useState([]);
  let [search, setSearch] = React.useState("");
  let [resultListOpen, setResultListOpen] = React.useState(false);
  let [hovered, setHovered] = React.useState(false);
  let PAGE_SIZE = 5;
  const [isVisible, setIsVisible] = React.useState(true);
  const [height, setHeight] = React.useState(0);

  const sm = useMediaQuery(themeWidth.breakpoints.down("sm"));
  const xs = useMediaQuery(themeWidth.breakpoints.down("xs"));
  const md = useMediaQuery(themeWidth.breakpoints.down("md"));
  const lg = useMediaQuery(themeWidth.breakpoints.down("lg"));

  React.useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  const listenToScroll = () => {
    let heightToHideFrom = 200;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    setHeight(winScroll);

    if (winScroll > heightToHideFrom) {
      isVisible && setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  let fetchArts = (searchPrompt) => {
    getAllArts(0, PAGE_SIZE, undefined, searchPrompt)
      .then((response) => {
        let data = response.data;

        data = data.map((entity) => {
          return {
            id: entity.id,
            name: entity.name,
            imageCompressedLocation: `${ASSETS_URL}/${entity.imageCompressedLocation}`,
            resolution: entity.resolution,
          };
        });

        setItemData((prevData) => [...data]);
      })
      .catch((e) => {
        toast("Something went wrong!");
      });
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleOpenSubscription = (event) =>
    setOpenSubscription(event.currentTarget);
  const handleOpenResultList = (event) => setResultListOpen(true);
  const handleCloseResultList = () => setResultListOpen(false);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleCloseSubscription = () => setOpenSubscription(false);
  let bearerToken = useSelector((state) => state.navigationReducer.bearerToken);
  let user = useSelector((state) => state.navigationReducer.user);
  let [searchRef, setSearchRef] = React.useState(React.useRef(null));

  React.useEffect(() => {
    axios.get("https://api.ipify.org/?format=json").then((res) => {
      console.log(res.data.ip);

      dispatch({
        type: SET_IP,
        payload: res.data.ip,
      });
    });
  }, []);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        icon={<PiUserCircle />}
        title="Profile"
        onClick={() => {
          window.location.href = "/profile";
        }}
      />
      <NotificationItem
        onClick={() => {
          window.location.href = "/downloads";
        }}
        icon={<PiDownloadSimple />}
        title="Downloads"
      />
      <NotificationItem
        onClick={() => {
          window.location.href = "/likes";
        }}
        icon={<PiHeart />}
        title="Likes"
      />
      <NotificationItem
        onClick={() => {
          window.location.href = "/bookmarks";
        }}
        icon={<PiStar />}
        title="Bookmarks"
      />
      <Divider></Divider>
      <NotificationItem
        onClick={() => {
          dispatch({
            type: SIGN_IN,
            payload: undefined,
          });

          dispatch({
            type: SET_AUTH,
            payload: "",
          });

          handleCloseMenu();
        }}
        icon={<PiSignOut />}
        title="Sign Out"
      />
    </Menu>
  );

  const renderSubscriptionMenu = () => (
    <Menu
      anchorEl={openSubscription}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openSubscription)}
      onClose={handleCloseSubscription}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        sx={{ display: "flex", justifyContent: "flex-start" }}
        title={`${user.subscriptionPlanNumberOfItemsLeft} Downloads left`}
      />
      <NotificationItem
        title={`Valid untill ${parseDate(
          new Date(user.subscriptionExpiresAt)
        )}`}
      />
      <Divider></Divider>
      <NotificationItem
        onClick={() => {
          window.location.href = "/profile";
        }}
        icon={<PiCrown />}
        title="Subscriptions"
      />
    </Menu>
  );

  const renderSearchMenu = () => (
    <Box
      anchorEl={searchRef}
      // anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className="dropdown-shadow"
      open={true}
      onClose={handleCloseResultList}
      style={{
        backgroundColor: "white",
        padding: 10,
        borderRadius: 10,
        width: 400,
      }}
      sx={{
        mt: 2,
        position: "absolute",
        zIndex: 1000,
      }}
    >
      {itemData.length !== 0 ? (
        itemData.map((item) => {
          return (
            <NotificationItem
              icon={
                <img
                  src={item.imageCompressedLocation}
                  style={{
                    width: 60,
                    height: 60,
                    borderRadius: "10px",
                    objectFit: "cover",
                  }}
                />
              }
              onClick={() => {
                window.location.href = `/digital-art/${item.id}`;
              }}
              title={item.name}
            />
          );
        })
      ) : (
        <Typography variant="overline">Not Found</Typography>
      )}
      <Divider></Divider>
      <NotificationItem
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => {
          window.location.href = `/home?name.contains=${search}`;
        }}
        title="See All"
      />
    </Box>
  );

  React.useEffect(() => {
    console.log(md);
  }, [xs, sm, md, lg]);

  return (
    <AppBar position="static">
      <Container
        style={{
          maxWidth: "none",
          paddingLeft: md ? 15 : 80,
        }}
      >
        <Toolbar disableGutters style={{}}>
          {!md && (
            <Box
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.location.href = "/home";
              }}
            >
              <img width={120} height={120} src={Logo} />
            </Box>
          )}

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.label}
                  onClick={() => {
                    window.location.href = page.src;
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.label}
                color={"success"}
                onClick={() => {
                  window.location.href = page.src;
                  handleCloseNavMenu();
                }}
                sx={{ my: 2, color: "black !important", display: "block" }}
              >
                {page.label}
              </Button>
            ))}
          </Box>

          <Box
            style={{ flex: 2, paddingRight: 20 }}
            sx={{ flexGrow: 0 }}
            onFocus={() => {
              handleOpenResultList();
            }}
            onBlur={(e) => {
              if (!hovered) handleCloseResultList();
            }}
          >
            <Search
              sx={{ borderRadius: "100px" }}
              value={search}
              onChange={(e) => {
                handleOpenResultList();
                fetchArts(e.target.value);
                setSearch(e.target.value);
              }}
              ref={(inputRef) => setSearchRef(inputRef)}
            >
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
            {resultListOpen && search != "" && renderSearchMenu()}
          </Box>
          <Box
            sx={{
              flexGrow: 0,
              display: "flex",
              columnGap: md ? 0 : 5,
              justifyContent: "flex-end",
              flexDirection: "row",
              flex: 1,
            }}
          >
            {bearerToken && user.subscriptionPlanNumberOfItemsLeft != null && (
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handleOpenSubscription}
              >
                <PiCrown size={20} />
                <Typography ml={2} mr={2} variant={"overline"}>
                  {`${user.subscriptionPlanNumberOfItemsLeft}`}
                </Typography>
              </Box>
            )}
            {renderSubscriptionMenu()}

            <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={""}
              aria-controls="notification-menu"
              aria-haspopup="true"
              variant="contained"
              onClick={bearerToken ? handleOpenMenu : handleOpen}
            >
              <PiUserBold />
              {bearerToken && !md && (
                <Typography ml={2} mr={2} variant={"overline"}>
                  {`${user.firstName ? user.firstName : ""} ${
                    user.lastName != null ? user.lastName : ""
                  }`}
                </Typography>
              )}
            </IconButton>
            {md && (
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="black"
              >
                <MdMenu />
              </IconButton>
            )}
            {renderMenu()}
          </Box>
        </Toolbar>
      </Container>
      <Modal
        keepMounted
        open={open}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          backgrF: "blur(10)",
        }}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          {open && (
            <SignIn
              onSignup={() => {
                handleClose();
                handleSignUpOpen();
              }}
              onComplete={() => {
                handleClose();
              }}
            />
          )}
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={signUpOpen}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          backgrF: "blur(10)",
        }}
        onClose={handleSignUpClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <SignUp onComplete={handleSignUpClose} />
        </Box>
      </Modal>

      {/* <MDBox
        className="fab"
        sx={{
          position: "fixed",
          zIndex: 1000,
          bottom: isVisible ? 60 : -60,
          right: 60,
        }}
      >
        <Fab
          sx={{ padding: 2, paddingLeft: 3, paddingRight: 3 }}
          variant="extended"
          color={"info"}
          onClick={() => {
            window.location.href = "https://bmc.link/smaiart";
          }}
          className="bg-indigo"
        >
          <PiCoffee size={25} style={{ marginRight: 10 }} />
          Buy me a coffee
        </Fab>
      </MDBox> */}
    </AppBar>
  );
}
export default ResponsiveAppBar;
