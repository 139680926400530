import React, { Suspense, useRef, useState, useEffect } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import {
  ContactShadows,
  Environment,
  useGLTF,
  OrbitControls,
} from "@react-three/drei";
import { HexColorPicker } from "react-colorful";
import { proxy, useSnapshot } from "valtio";
import { WomanTextureModel } from "./Shirt-texture";
import axios from "axios";
import { Buffer } from "buffer";
// import gltf from "./assets/shirt-texture.gltf";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { ManTextureModel } from "./Shirt-man-texture";
import { MugModel } from "./Mug";
import Picker from "./Picker";
// Using a Valtio state model to bridge reactivity between
// the canvas and the dom, both can write to it and/or react to it.
// laces: "#ffffff",
// mesh: "#ffffff",
// caps: "#ffffff",
// inner: "#ffffff",
// sole: "#ffffff",
// stripes: "#ffffff",
// band: "#ffffff",
// patch: "#ffffff",
const state = proxy({
  count: 0,
  current: null,
  items: {},
});
console.log(state.items);

export default function ShoeSider(props) {
  // const { invalidate } = useThree();

  return (
    <>
      <div
        className="grid flex max-w-sm md:max-w-full"
        style={{ width: "100%", height: "80vh" }}
      >
        <Canvas
          className="canvas flex"
          shadows
          height={200}
          // dpr={[1, 2]}
          // style={{ background: "#171717" }}
          camera={{ position: [0, 0, 4], fov: 50 }}
        >
          <spotLight
            intensity={0.9}
            angle={0.3}
            penumbra={0}
            position={[10, 15, 10]}
            castShadow
          />
          <Suspense fallback={null}>
            {props.type === "T-Shirt" ? (
              props.male ? (
                <ManTextureModel
                  url={props.url}
                  color={props.color}
                  state={state}
                />
              ) : (
                <WomanTextureModel
                  url={props.url}
                  color={props.color}
                  state={state}
                />
              )
            ) : (
              <MugModel url={props.url} color={props.color} state={state} />
            )}
            <ContactShadows
              rotation-x={Math.PI}
              position={[0, 0, -10.0]}
              opacity={0.25}
              width={10}
              height={10}
              blur={1.5}
              far={0}
            />{" "}
          </Suspense>
          <OrbitControls />
        </Canvas>
      </div>
    </>
  );
}
