export const HOST_URL = "https://api.smaiart.com";
export const ASSETS_URL = "https://static.smaiart.com";
export const PREMIUM_URL = "https://premium.smaiart.com";
// export const HOST_URL = "http://localhost:8080";
export const ADMIN_URL = "https://api.smaiart.com/api/admin";
export const SIGN_IN = "SIGN_IN";
export const SET_AUTH = "SET_AUTH";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const USER = "USER";
export const SET_IP = "SET_IP";
