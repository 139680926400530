import {
  Button,
  Chip,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Drawer,
  Typography,
  Box,
  Paper,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import React, { Component, useCallback, useEffect, useState } from "react";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import {
  PiHeartFill,
  PiStarFill,
  PiHeart,
  PiStar,
  PiChatText,
  PiDownloadSimple,
  PiTShirt,
  PiEye,
  PiChatCenteredText,
} from "react-icons/pi";
import ImageViewer from "react-simple-image-viewer";
import DrawerComments from "layouts/drawer-comments";
import DrawerLikes from "layouts/drawer-likes";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getArtById } from "actions";
import { toast } from "react-toastify";
import MDBox from "components/MDBox";
import { likeDislikeArt } from "actions";
import { useDispatch, useSelector } from "react-redux";
import { saveUnsaveArt } from "actions";
import axios from "axios";
import { HOST_URL } from "constants";
import SubscriptionNote from "layouts/subscription-note";
import styled from "@emotion/styled";
import { Download, DownloadOutlined, Share } from "@mui/icons-material";
import { PiCrownDuotone } from "react-icons/pi";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  RedditShareButton,
  PinterestShareButton,
} from "react-share";
import {
  FaFacebook,
  FaLink,
  FaPinterest,
  FaReddit,
  FaTwitter,
} from "react-icons/fa";
import { USER } from "constants";
import { getUserAccountData } from "actions/UserAction";
import { getHighResImage } from "actions/UserAction";
import { download } from "utils";
import { ASSETS_URL } from "constants";
import { mapAuthorities } from "utils";
import { PREMIUM_URL } from "constants";
import { downloadImage } from "utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  boxShadow: 24,
  width: "90%",
  maxWidth: "1200px",
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const themeWidth = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

function SelectedDigitalArt() {
  let [piece, setPiece] = useState({
    imageUrl: "",
    title: "",
    credits: "Siyavush Mammad",
    description: "",
    categories: [],
    likesCount: 0,
    starsCount: 0,
    commentsCount: 0,
    viewsCount: 0,
    downloadsCount: 0,
    liked: false,
    starred: false,
    lowRes: {
      width: 512,
      height: 512,
    },
    highRes: {
      width: 512,
      height: 512,
    },
  });
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const { id } = useParams();
  let [drawer, setDrawer] = useState(false);
  let [drawerContent, setDrawerContent] = useState(undefined);
  let bearerToken = useSelector((state) => state.navigationReducer.bearerToken);
  let ipAddress = useSelector((state) => state.navigationReducer.ipAddress);
  let user = useSelector((state) => state.navigationReducer.user);
  let [open, setOpen] = useState(false);
  let attribution =
    "Thank you for your interest in SMAIART images. While this image is protected by copyright laws, we're granting you the flexibility to use it both for personal and commercial purposes, as long as it doesn't include photorealistic representations of people. Always here to support your creative needs. Let us know if you have questions!";
  let attribution1 = "";
  let authorities = useSelector(
    (state) => state.navigationReducer.user.authorities
  );

  const sm = useMediaQuery(themeWidth.breakpoints.down("sm"));
  const xs = useMediaQuery(themeWidth.breakpoints.down("xs"));
  const md = useMediaQuery(themeWidth.breakpoints.down("md"));
  const lg = useMediaQuery(themeWidth.breakpoints.down("lg"));
  const handleOpen = (event) => setOpen(event.currentTarget);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getArtById(id, bearerToken)
      .then((response) => {
        console.log(response.data);

        let entity = response.data;
        let data = {
          id: entity.id,
          imageUrl: `${
            mapAuthorities(authorities) ? PREMIUM_URL : ASSETS_URL
          }/${entity.imageCompressedLocation}`,
          imageName: entity.imageCompressedLocation,
          imageNameHighRes: entity.imageLocation,
          title: entity.name,
          credits: "Siyavush Mammad",
          description: entity.description,
          categories: entity.categories,
          likesCount: entity.likes,
          commentsCount: entity.comments,
          viewsCount: entity.views,
          downloadsCount: entity.downloads,
          liked: entity.liked,
          starred: entity.saved,
          lowRes: entity.lowRes,
          highRes: entity.highRes,
          price: entity.price,
        };

        setPiece(data);
      })
      .catch((error) => {
        toast("Something went wrong!");
      });
  }, []);

  let onLikeClick = () => {
    likeDislikeArt(id, bearerToken)
      .then((response) => {
        if (piece.liked) {
          setPiece({
            ...piece,
            liked: false,
            likesCount: piece.likesCount - 1,
          });
        } else {
          setPiece({ ...piece, liked: true, likesCount: piece.likesCount + 1 });
        }
      })
      .catch((error) => {
        toast("Something went wrong!");
      });
  };

  let onStarClick = () => {
    if (bearerToken) {
      saveUnsaveArt(id, bearerToken)
        .then((response) => {
          if (piece.starred) {
            setPiece({
              ...piece,
              starred: false,
              starsCount: piece.starsCount - 1,
            });
          } else {
            setPiece({
              ...piece,
              starred: true,
              starsCount: piece.starsCount + 1,
            });
          }
        })
        .catch((error) => {
          toast("Something went wrong!");
        });
    } else {
      toast("You need to be signed in to Bookmark!");
    }
  };

  let onShirtClicked = () => {
    window.location.href = `/merch?art=${id}`;
  };

  const openImageViewer = () => {
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const openComments = () => {
    setDrawerContent("comments");
    toggleDrawer(true);
  };

  const openLikes = () => {
    setDrawerContent("likes");
    toggleDrawer(true);
  };

  const toggleDrawer = (state) => {
    setDrawer(state);
  };

  useEffect(() => {
    if (piece != undefined) {
      document.title = "Smaiart - " + piece.title;
    }
  }, [piece]);

  const renderDrawerContent = (drawerContent) => {
    switch (drawerContent) {
      case "comments":
        return (
          <DrawerComments
            id={id}
            incrementComments={() => {
              setPiece({ ...piece, commentsCount: piece.commentsCount + 1 });
            }}
          />
        );
      case "likes":
        return <DrawerLikes />;

      default:
        return <div></div>;
    }
  };

  return (
    <Grid container style={{ width: "100%" }} pt={10}>
      <Grid
        container
        style={{ display: "flex", flexDirection: "row", rowGap: 100 }}
      >
        <Grid
          item
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <img
            src={`${piece.imageUrl}`}
            style={{
              maxWidth: "512px",
              maxHeight: "512px",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={() => openImageViewer()}
            srcSet={`${piece.imageUrl}`}
            alt={piece.title}
            loading="lazy"
          />

          <Grid
            container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            item
            columnGap={3}
            mt={5}
          >
            <Grid item>
              {" "}
              <MDBox
                className="shadow"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  backgroundColor: "#fff",
                  padding: "5px",

                  borderRadius: "50px",
                }}
                onClick={onLikeClick}
              >
                {piece.liked ? (
                  <PiHeartFill className="actions" size={30} fill="#4f4f4f" />
                ) : (
                  <PiHeart className="actions" size={30} color="#4f4f4f" />
                )}
              </MDBox>
            </Grid>

            <Grid item>
              {" "}
              <MDBox
                className="shadow"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  backgroundColor: "#fff",
                  padding: "5px",

                  borderRadius: "50px",
                }}
                onClick={onStarClick}
              >
                {piece.starred ? (
                  <PiStarFill className="actions" size={30} fill="#4f4f4f" />
                ) : (
                  <PiStar className="actions" size={30} color="#4f4f4f" />
                )}
              </MDBox>
            </Grid>

            <Grid item>
              {" "}
              <MDBox
                className="shadow"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  backgroundColor: "#fff",
                  padding: "5px",

                  borderRadius: "50px",
                }}
                onClick={openComments}
              >
                <PiChatText className="actions" size={30} color="#4f4f4f" />
              </MDBox>
            </Grid>

            <Grid item>
              {" "}
              <MDBox
                className="shadow"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  backgroundColor: "#fff",
                  padding: "5px",

                  borderRadius: "50px",
                }}
                onClick={onShirtClicked}
              >
                <PiTShirt className="actions" size={30} color="#4f4f4f" />
              </MDBox>
            </Grid>
          </Grid>

          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
            mt={5}
            rowGap={2}
          >
            <Grid
              container
              spacing={1}
              mt={4}
              style={{ width: sm ? "90%" : "70%" }}
            >
              <Grid item xs={6}>
                <a
                  href={`${piece.imageUrl}`}
                  // onClick={() => {
                  //   axios
                  //     .get(
                  //       `${HOST_URL}/api/arts/image/${piece.imageName}/download?compressed=true&ipAddress=${ipAddress}`
                  //     )
                  //     .then((response) => {
                  //       // window.location.href =
                  //       //   "data:application/octet-stream;base64," +
                  //       //   response.data;
                  //       downloadImage(piece.imageUrl);
                  //     });
                  // }}
                  filename={`${piece.imageName}.png`}
                  download
                >
                  <Button
                    style={{
                      width: "100%",
                      color: "white",
                      borderRadius: "50px",
                      height: "44px",
                      backgroundColor: "#2bc48a",
                    }}
                    variant={"contained"}
                    size="medium"
                  >
                    {`Download ${
                      mapAuthorities(authorities) ? "" : "with watermark"
                    } ${piece.lowRes.height} x ${piece.lowRes.width} px`}
                  </Button>
                </a>
              </Grid>
              <Grid
                item
                xs={6}
                onClick={(e) => {
                  if (
                    bearerToken &&
                    (user.subscriptionPlanNumberOfItemsLeft > 0 ||
                      user.subscriptionPlanNumberOfItemsLeft == -1)
                  ) {
                    console.log(user);

                    getHighResImage(bearerToken, piece.imageNameHighRes)
                      .then((response) => {
                        // window.location.href =
                        //   "data:application/octet-stream;base64," +
                        //   response.data;

                        download(
                          response.data,
                          piece.title +
                            "." +
                            piece.imageNameHighRes.split(".")[1]
                        );
                      })
                      .then((response) => {
                        getUserAccountData(bearerToken)
                          .then(function (response) {
                            if (response.error) {
                              toast("Wrong Email/Password");
                            }

                            dispatch({
                              type: USER,
                              payload: response.data,
                            });
                            return response.data.id_token;
                          })
                          .catch(function (error) {
                            console.log(error);
                            toast(error.response.data.title);
                          });
                      });
                  } else {
                    window.location.href = `${piece.id}/purchase`;
                  }
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    color: "white",
                    borderRadius: "50px",
                    height: "44px",
                    backgroundColor: "#2bc48a",
                  }}
                  variant={"contained"}
                  size="medium"
                  startIcon={<PiCrownDuotone size={25} />}
                >
                  {bearerToken &&
                  (user.subscriptionPlanNumberOfItemsLeft > 0 ||
                    user.subscriptionPlanNumberOfItemsLeft == -1)
                    ? `Download ${piece.highRes.height} x ${piece.highRes.width} px`
                    : `Buy ${piece.highRes.height} x ${piece.highRes.width} px `}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      {/* <Button
                        sx={{ color: "white !important" }}
                        variant="contained"
                        {...bindTrigger(popupState)}
                      >
                        Download
                      </Button> */}

                      <Button
                        style={{
                          width: "100%",
                          color: "#424242",
                          borderRadius: "50px",
                          borderColor: "#a8a7a7",
                          height: "44px",
                        }}
                        variant={"outlined"}
                        size="medium"
                        {...bindTrigger(popupState)}
                        startIcon={<Share />}
                      >
                        {`Share`}
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem>
                          {/* <FaFacebook size={24} color="#3b5998" />
                          <Typography ml={2} variant={"overline"}>
                            Facebook

                            
                          </Typography> */}

                          <FacebookShareButton
                            // url={window.location.href}
                            url={"https://smaiart.com"}
                            quote={`Check out ${piece.title} at Smaiart! smaiart.com/digital-art/${piece.id}`}
                            hashtag={"Art"}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FaFacebook size={24} color="#3b5998" />
                            <Typography ml={2} variant={"overline"}>
                              Facebook
                            </Typography>
                          </FacebookShareButton>
                        </MenuItem>
                        <MenuItem>
                          <TwitterShareButton
                            // url={window.location.href}
                            url={"https://smaiart.com"}
                            title={`Check out ${piece.title} at Smaiart!`}
                            hashtags={piece.categories.map((category) => {
                              return category.name;
                            })}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FaTwitter color="#1DA1F2" size={24} />
                            <Typography ml={2} variant={"overline"}>
                              Twitter
                            </Typography>
                          </TwitterShareButton>
                        </MenuItem>
                        <MenuItem>
                          <PinterestShareButton
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            url={"https://smaiart.com"}
                            description={`Check out ${piece.title} at Smaiart!`}
                            media={`${piece.imageUrl}`}
                          >
                            <FaPinterest size={24} color="#ff4500" />

                            <Typography ml={2} variant={"overline"}>
                              Pinterest
                            </Typography>
                          </PinterestShareButton>
                        </MenuItem>

                        <MenuItem>
                          <RedditShareButton
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            url={"https://smaiart.com"}
                            title={`Check out ${piece.title} at Smaiart!`}
                          >
                            <FaReddit size={24} color="#ff4500" />

                            <Typography ml={2} variant={"overline"}>
                              Reddit
                            </Typography>
                          </RedditShareButton>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            toast("Copied to the Clipboard!");
                            navigator.clipboard.writeText(window.location.href);
                          }}
                        >
                          <FaLink size={24} />

                          <Typography ml={2} variant={"overline"}>
                            Copy Link
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </Grid>

              <Grid item xs={6}>
                <Button
                  style={{
                    width: "100%",
                    color: "#424242",
                    borderRadius: "50px",
                    borderColor: "#a8a7a7",
                    height: "44px",
                  }}
                  onClick={(e) => {
                    if (!bearerToken) {
                      handleOpen(e);
                      return;
                    }

                    if (
                      !(
                        user.authorities.includes("ROLE_PRO") ||
                        user.authorities.includes("ROLE_ULTIMATE")
                      )
                    ) {
                      handleOpen(e);
                      return;
                    }
                  }}
                  variant={"outlined"}
                  size="medium"
                  startIcon={<PiCrownDuotone size={25} />}
                >
                  {`Subscribe `}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
            textAlign: "left",
            padding: md ? "15px" : "0px",
          }}
        >
          <Typography variant="h3">{piece.title}</Typography>
          <Typography variant="overline">By {piece.credits}</Typography>

          <Divider variant="middle" style={{ width: "100%" }} />

          <Typography variant="overline" align="left" pr={5}>
            {piece.description}
          </Typography>

          <Grid
            item
            container
            style={{ display: "flex", flexDirection: "row" }}
            mt={4}
          >
            <Grid item container columnGap={4}>
              <Grid
                item
                style={{ display: "flex", alignItems: "center" }}
                columnGap={0.5}
              >
                <Grid item>
                  {" "}
                  <PiHeart className="actions" size={25} color="black" />
                </Grid>
                <Grid item>
                  <Typography className="actions" variant="overline">
                    {piece.likesCount}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                onClick={() => {
                  openComments();
                }}
                style={{ display: "flex", alignItems: "center" }}
                columnGap={0.5}
                // flex={1}
              >
                <Grid item>
                  {" "}
                  <PiChatCenteredText
                    className="actions"
                    size={25}
                    color="black"
                  />
                </Grid>
                <Grid item>
                  <Typography className="actions" variant="overline">
                    {piece.commentsCount}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                style={{ display: "flex", alignItems: "center" }}
                columnGap={0.5}
                // flex={1}
              >
                <Grid item>
                  {" "}
                  <PiEye className="actions" size={25} color="black" />
                </Grid>
                <Grid item>
                  <Typography className="actions" variant="overline">
                    {piece.viewsCount}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                style={{ display: "flex", alignItems: "center" }}
                columnGap={0.5}
              >
                <Grid item>
                  {" "}
                  <PiDownloadSimple
                    className="actions"
                    size={25}
                    color="black"
                  />
                </Grid>
                <Grid item>
                  <Typography className="actions" variant="overline">
                    {piece.downloadsCount}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider variant="middle" style={{ width: "100%" }} />
          <Typography
            variant="overline"
            style={{ fontSize: "8pt" }}
            align="left"
            pr={5}
          >
            {attribution}
          </Typography>
          <Typography
            mt={1}
            variant="overline"
            style={{ fontSize: "8pt" }}
            align="left"
            pr={5}
          >
            {attribution1}
          </Typography>

          <Grid
            item
            container
            style={{
              display: "flex",
              flexDirection: "row",
            }}
            columnGap={1}
            mt={3}
          >
            {piece.categories.map((category) => {
              return (
                <Grid item>
                  <Chip
                    onClick={() => {
                      console.log(category.id);
                    }}
                    sx={{ my: 0.5 }}
                    className={"chip"}
                    label={category.name}
                    variant="outlined"
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid container></Grid>

      {isViewerOpen && (
        <ImageViewer
          src={[piece.imageUrl]}
          currentIndex={0}
          backgroundStyle={{ backgroundColor: "#000000bf" }}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}

      <React.Fragment key={"drawer"}>
        <Drawer
          anchor={"right"}
          open={drawer}
          PaperProps={{
            sx: { width: "25%" },
          }}
          BackdropProps={{ style: { opacity: 0.2 } }}
          onClose={() => toggleDrawer(false)}
        >
          {renderDrawerContent(drawerContent)}
        </Drawer>
      </React.Fragment>

      <Modal
        keepMounted
        open={open}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.05)",
        }}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>{open && <SubscriptionNote />}</Box>
      </Modal>
    </Grid>
  );
}

export default SelectedDigitalArt;
