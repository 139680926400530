import {
  AppBar,
  Box,
  Card,
  Divider,
  FormControl,
  Grid,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import theme from "assets/theme";
import { BsCardImage, BsFillCupFill } from "react-icons/bs";
import { FaTshirt } from "react-icons/fa";
import ImageViewer from "react-simple-image-viewer";

import { useEffect, useState } from "react";

import ShoeSider from "./ShoEnv";
import Picker from "./Picker";
import { proxy } from "valtio";
import { useParams, useSearchParams } from "react-router-dom";
import { getAllArts } from "actions";
import { getArtById } from "actions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { HOST_URL } from "constants";
import { FcGoogle } from "react-icons/fc";
import { ASSETS_URL } from "constants";

const themeWidth = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

function Merch() {
  const genders = ["Male", "Female"];
  const merchTypes = ["Mug", "T-Shirt"];
  const colors = ["Red", "Black", "Green", "Blue"];
  const sizes = [
    "XXXS",
    "XXS",
    "XS",
    "S",
    "M",
    "L",
    "XL",
    "XXL",
    "XXXL",
    "XXXXL",
  ];
  const [gender, setGender] = useState("Male");
  const [merchType, setMerchType] = useState(merchTypes[1]);
  const [color, setColor] = useState("#000000");
  const [size, setSize] = useState("M");
  const [email, setEmail] = useState("");
  const [source, setSource] = useState("");
  const [itemData, setItemData] = useState([]);
  let [xTotalCount, setXTotalCount] = useState(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  let [page, setPage] = useState(0);
  let PAGE_SIZE = 8;
  let selectedArtId = searchParams.get("art");
  let bearerToken = useSelector((state) => state.navigationReducer.bearerToken);

  const sm = useMediaQuery(themeWidth.breakpoints.down("sm"));
  const xs = useMediaQuery(themeWidth.breakpoints.down("xs"));
  const md = useMediaQuery(themeWidth.breakpoints.down("md"));
  const lg = useMediaQuery(themeWidth.breakpoints.down("lg"));

  useEffect(() => {
    toast("Coming soon...");
  }, []);

  useEffect(() => {
    if (selectedArtId) {
      getArtById(selectedArtId, bearerToken)
        .then((response) => {
          let entity = response.data;
          let data = `${ASSETS_URL}/${entity.imageCompressedLocation}`;

          setSource(data);
        })
        .catch((error) => {
          toast("Something went wrong!");
        });
    }
  }, []);

  useEffect(() => {
    document.title = "Smaiart - Merch";
  }, []);

  useEffect(() => {
    getAllArts(page, PAGE_SIZE)
      .then((response) => {
        let data = response.data;

        data = data.map((entity) => {
          return {
            id: entity.id,
            name: entity.name,
            imageCompressedLocation: `${ASSETS_URL}/${entity.imageCompressedLocation}`,
          };
        });

        setItemData(data);

        if (!source && !selectedArtId)
          setSource(data[0].imageCompressedLocation);
        setXTotalCount((prevData) => response.headers["x-total-count"]);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [page]);

  const state = proxy({
    count: 0,
    current: null,
    items: {},
  });

  function getStyles(name, gender, theme) {
    return {
      fontWeight:
        gender.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log(event.target.value);

    setGender(value);
  };

  const handleMerchTypeChange = (event) => {
    const {
      target: { value },
    } = event;
    setMerchType(value);
  };

  const handleSizeChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log(event);

    setSize(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const openImageViewer = () => {
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  return (
    <div className="App ">
      <Grid container>
        <Grid item flex={2}>
          <Grid style={{ display: "flex", flexDirection: "column" }} container>
            <Grid item flex={4}>
              {source && (
                <ShoeSider
                  url={source}
                  male={gender === "Male"}
                  type={merchType}
                  color={color}
                />
              )}
              {md ? (
                <MDBox
                  className="shadow"
                  ml={5}
                  mb={5}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    backgroundColor: "#fff",
                    padding: "5px",
                    cursor: "pointer",
                    borderRadius: "50px",
                    border: "1.5px solid #344767",
                  }}
                  onClick={openImageViewer}
                >
                  <BsCardImage size={20} />
                </MDBox>
              ) : (
                <Paper
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    padding: "5px",
                    bottom: 50,
                    left: 50,
                    cursor: "pointer",
                  }}
                  variant="outlined"
                >
                  <img
                    onClick={openImageViewer}
                    style={{
                      height: "250px",
                    }}
                    src={source}
                  />
                </Paper>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item flex={1}>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox
              role="form"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Grid style={{ flex: 1, height: "500px" }} item>
                <ImageList variant="masonry" cols={2} gap={8}>
                  {itemData.map((item) => (
                    <ImageListItem key={item.imageCompressedLocation}>
                      <img
                        onClick={() => {
                          setSource(`${item.imageCompressedLocation}`);
                        }}
                        className="image-tile"
                        style={{
                          maxHeight: "100px",
                          minHeight: "100px",
                          backgroundColor: "#000",
                        }}
                        src={`${item.imageCompressedLocation}`}
                        srcSet={`${item.imageCompressedLocation}`}
                        alt={item.name}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  fullWidth
                >
                  {xTotalCount && (
                    <Pagination
                      onChange={(event, newPage) => {
                        handleChangePage(event, newPage);
                      }}
                      count={Math.ceil(xTotalCount / 8)}
                    />
                  )}
                </Box>
                {/* </Box> */}
              </Grid>

              <Grid item style={{ flex: 1 }}>
                <MDBox mt={1} mb={4}>
                  <Divider />
                </MDBox>

                <MDBox style={{ justifyContent: "center" }} mb={2}>
                  <Picker
                    setColor={(col) => {
                      setColor(col);
                    }}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="email"
                    label="Email"
                    fullWidth
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </MDBox>

                <MDBox mb={2}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-multiple-name-label">Type</InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      value={merchType}
                      onChange={handleMerchTypeChange}
                      input={<OutlinedInput label="Type" />}
                      MenuProps={MenuProps}
                      style={{ padding: 12 }}
                    >
                      {merchTypes.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          style={getStyles(name, gender, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MDBox>
                {merchType == merchTypes[1] && (
                  <>
                    <MDBox mb={2}>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="demo-multiple-name-label">
                          Gender
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          value={gender}
                          onChange={handleChange}
                          input={<OutlinedInput label="Gender" />}
                          MenuProps={MenuProps}
                          style={{ padding: 12 }}
                        >
                          {genders.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, gender, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </MDBox>

                    <MDBox mb={2}>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="demo-multiple-name-label">
                          Size
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          value={size}
                          onChange={handleSizeChange}
                          input={<OutlinedInput label="Size" />}
                          MenuProps={MenuProps}
                          style={{ padding: 12 }}
                        >
                          {sizes.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, size, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </MDBox>
                  </>
                )}
                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    size="large"
                    fullWidth
                  >
                    order
                  </MDButton>
                </MDBox>
              </Grid>
              <Divider />
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      {isViewerOpen && (
        <ImageViewer
          src={[source]}
          currentIndex={0}
          backgroundStyle={{ backgroundColor: "#000000bf" }}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </div>
  );
}

export default Merch;
