import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Modal,
  PaginationItem,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import { ASSETS_URL } from "constants";
import { HOST_URL } from "constants";
import SignIn from "layouts/sign-in";
import SignUp from "layouts/sign-up";
import React, { Component, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

function SubscriptionPlans({ children }) {
  let [selectedCredits, setSelectedCredits] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [signUpOpen, setSignupOpen] = React.useState(false);
  let bearerToken = useSelector((state) => state.navigationReducer.bearerToken);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let handleSignUpOpen = () => setSignupOpen(true);
  const handleSignUpClose = () => setSignupOpen(false);

  const plans = [
    {
      title: "Basic",
      offer: "Unlimited Images with Watermaks",
      imagesCount: 10,
      price: 0,
      features: ["Basic Support", "$9 per High Resolution Image"],

      selected: true,
    },
    {
      title: "Pro",
      imagesCount: 50,
      offer: "Unlimited Low Resoluiton Images",
      price: 10,
      features: ["24/7 Support", "Premium Content"],
      credits: [
        { index: 0, amount: 5, price: 40, one: 8 },
        { index: 1, amount: 10, price: 75, one: 7.5 },
        { index: 2, amount: 25, price: 175, one: 7 },
        { index: 3, amount: 40, price: 240, one: 6 },
      ],
      recommended: true,
      selected: false,
    },
    {
      title: "Ultimate",
      imagesCount: 100,
      offer: "100 High Resolution Images",
      price: 550,
      features: ["Priority Support", "All Premium Features"],
      selected: false,
    },
  ];

  const handleSubscribe = (plan) => {
    // Handle Stripe payment process here...

    if (!bearerToken) {
      handleOpen();
    } else {
      if (plan.title == "Pro") {
        window.location.href = `/subscription-checkout?plan=${plan.title}&numberOfItems=${plan.credits[selectedCredits].amount}`;
      } else {
        window.location.href = `/subscription-checkout?plan=${plan.title}&numberOfItems=100`;
      }
    }
    console.log(`Subscribing to: ${plan.title}`);
  };

  let renderPrice = (plan) => {
    if (plan.price == 0) return "Free";
    else {
      if (plan.credits) {
        let price = plan.credits[selectedCredits].price;
        return `$${price} `;
      } else {
        return `$${plan.price} `;
      }
    }
  };

  return (
    <Card
      sx={{ height: "100%", boxShadow: "none" }}
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        paddingTop: 100,
        paddingBottom: 10,
        backgroundSize: "cover",
        background: `url(${ASSETS_URL}/7ea2ea13-4cf7-43a0-8c22-d16396ac752c.jpg)`,
      }}
    >
      <MDBox p={2}>
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            columnGap: 15,
          }}
          p={0}
          m={0}
        >
          <Grid
            container
            style={{ display: "flex", alignItems: "center" }}
            spacing={3}
          >
            {plans.map((plan, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card
                  elevation={plan.recommended ? 8 : 1}
                  style={plan.recommended ? { backgroundColor: "#f5f5f5" } : {}}
                >
                  {plan.recommended && (
                    <CardMedia
                      sx={{
                        height: 35,
                        backgroundColor: "#007aff",
                        margin: 0,
                        borderEndEndRadius: 0,
                        borderEndStartRadius: 0,
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="overline"
                        style={{ color: "#ffffff", fontWeight: "bold" }}
                      >
                        ✨ Most Popular ✨
                      </Typography>
                    </CardMedia>
                  )}
                  <CardContent
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      padding: 40,
                      paddingTop: plan.recommended ? 30 : 40,
                    }}
                  >
                    <Chip
                      style={{ marginBottom: 10 }}
                      label={
                        plan.credits
                          ? `${plan.credits[selectedCredits].amount} High Resolution Images`
                          : plan.offer
                      }
                    />

                    <Typography variant="h5" component="div">
                      {plan.title}
                    </Typography>
                    <Box
                      my={2}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {plan.features.map((feature) => (
                        <Typography key={feature} variant="overline">
                          {feature}
                        </Typography>
                      ))}
                    </Box>
                    {plan.credits && (
                      <Box
                        m={4}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="caption"
                          mb={1}
                          style={{ fontWeight: "bold" }}
                        >
                          Images per month
                        </Typography>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          {plan.credits.map((credit) => (
                            <PaginationItem
                              page={credit.amount}
                              shape={"circular"}
                              size={"large"}
                              color="info"
                              onClick={() => {
                                setSelectedCredits(credit.index);
                              }}
                              selected={selectedCredits == credit.index}
                            />
                          ))}
                        </Box>
                      </Box>
                    )}
                    <Typography variant="h2">
                      {renderPrice(plan)}
                      {plan.duration ? (
                        <span style={{ fontSize: "1.5rem" }}>
                          /{plan.duration}
                        </span>
                      ) : (
                        ""
                      )}
                    </Typography>
                    <Chip
                      style={{ marginBottom: 10, marginTop: 10 }}
                      label={
                        plan.credits
                          ? `$${plan.credits[selectedCredits].one} per image`
                          : plan.title == "Ultimate"
                          ? "$5.5 per image"
                          : plan.offer
                      }
                    />
                  </CardContent>
                  <CardActions
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      fullWidth
                      size="medium"
                      variant="contained"
                      color={plan.recommended ? "primary" : "secondary"}
                      style={{
                        color: "#ffffff",
                        borderRadius: "20px",
                        marginBottom: 20,
                        width: "90%",
                      }}
                      onClick={() => handleSubscribe(plan)}
                      disabled={plan.selected ? true : false}
                    >
                      {plan.selected ? "Selected" : "Subscribe"}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </MDBox>
      </MDBox>

      <Typography
        style={{
          color: "#ffffff",
          marginBottom: 10,
          marginTop: 30,
        }}
        variant="caption"
      >
        Prices shown in US Dollars. Taxes may apply.
      </Typography>

      <Modal
        keepMounted
        open={open}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          backgrF: "blur(10)",
        }}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          {open && (
            <SignIn
              onSignup={() => {
                handleClose();
                handleSignUpOpen();
              }}
              onComplete={() => {
                toast("You can now proceed with subscription!");

                handleClose();
              }}
            />
          )}
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={signUpOpen}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          backgrF: "blur(10)",
        }}
        onClose={handleSignUpClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <SignUp onComplete={handleSignUpClose} />
        </Box>
      </Modal>
    </Card>
  );
}

export default SubscriptionPlans;
