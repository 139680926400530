import { Grid, Typography } from "@mui/material";
import { singleSignOnAuthorise } from "actions/UserAction";
import { getUserAccountData } from "actions/UserAction";
import { singleSignOn } from "actions/UserAction";
import { USER } from "constants";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import "./index.css";
import { SIGN_IN } from "constants";

export const SsoAuthenticationPage = () => {
  const { type } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(searchParams.get("code"));
    singleSignOnAuthorise(type, searchParams)
      .then((response) => {
        const token = response.data.id_token;

        dispatch({
          type: SIGN_IN,
          payload: token,
        });

        getUserAccountData(token)
          .then(function (response) {
            if (response.error) {
              toast("Something went wrong!");
            }

            console.log(response.data);

            dispatch({
              type: USER,
              payload: response.data,
            });
            return response.data.id_token;
          })
          .finally((e) => {
            window.location.href = "/home";
          })
          .catch(function (error) {
            console.log(error);
            toast(error.response.data.title);
          });
      })

      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <Grid
      pt={10}
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div class="scene">
        <div class="cube-wrapper">
          <a href="https://www.rareprogrammer.com/bouncing-cube-css-loader">
            <div class="cube">
              <div class="cube-faces">
                <div class="cube-face shadow"></div>
                <div class="cube-face bottom"></div>
                <div class="cube-face top"></div>
                <div class="cube-face left"></div>
                <div class="cube-face right"></div>
                <div class="cube-face back"></div>
                <div class="cube-face front"></div>
              </div>
            </div>
          </a>
        </div>
      </div>

      <Typography variant="h5" mt={2}>
        Redirecting...
      </Typography>
    </Grid>
  );
};
