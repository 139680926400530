import React, { Component, useEffect, useState } from "react";

import { Divider, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import MDBox from "components/MDBox";
import { HomeMiniOutlined } from "@mui/icons-material";
import { PiHouse, PiHouseBold, PiHouseLine } from "react-icons/pi";
import { HOST_URL } from "constants";
import { useSearchParams } from "react-router-dom";
import { activateAccount } from "actions/UserAction";
import { ASSETS_URL } from "constants";

function ActivationSuccessful() {
  let user = useSelector((state) => state.navigationReducer.user);
  const [searchParams, setSearchParams] = useSearchParams();
  let [header, setHeader] = useState("Successfully Activated");
  let [subHeader, setSubHeader] = useState("Account activated");
  let [body, setBody] = useState(
    "Your account was successfully activated! You can now proceed to Sign In."
  );

  useEffect(() => {
    let key = searchParams.get("key");

    activateAccount(key)
      .then((response) => {})
      .catch((error) => {
        setHeader("Activation Failure");
        setSubHeader("Failed to activate an account");

        console.log(error.response.data.detail);
        setBody(error.response.data.detail);
      });
  }, []);
  return (
    <Grid container style={{ width: "100%" }} pt={10}>
      <Grid container style={{ display: "flex", flexDirection: "row" }}>
        <Grid
          item
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <img
            src={`${ASSETS_URL}/22b08b19-f2a0-4593-98ff-6aee4e0b3d77.jpg`}
            style={{
              maxWidth: "512px",
              maxHeight: "512px",
              borderRadius: "10px",
            }}
            srcSet={`${ASSETS_URL}/22b08b19-f2a0-4593-98ff-6aee4e0b3d77.jpg`}
            alt={""}
            loading="lazy"
          />
        </Grid>
        <Grid
          item
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <Typography variant="h3">{header}</Typography>
          <Typography variant="overline">{subHeader}</Typography>

          <Divider variant="middle" style={{ width: "100%" }} />

          <Typography variant="overline">{body}</Typography>

          <MDBox
            mt={20}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              cursor: "pointer",
              width: "100%",
            }}
            onClick={() => {
              window.location.href = "/home";
            }}
          >
            <PiHouseBold style={{ marginRight: 10 }} size={20} />
            <Typography
              variant="overline"
              style={{ fontWeight: "bold", textDecoration: "underline" }}
            >
              {" "}
              Back to Home
            </Typography>
          </MDBox>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ActivationSuccessful;
