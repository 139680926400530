import { Chip, Divider, Grid, Typography } from "@mui/material";
import React, { Component, useEffect, useState } from "react";
import Image from "assets/profile_pic.jpg";

import { Link } from "react-router-dom";


function About() {

  let categories = ["3D", "AI Generated Art", "Traditional Art", "Digital Art"];
  let imageUrl = "";
  let title = "Siyavush Mammad";
  let description = [
    "Step into the digital realm of Siyavush Mammad, an artist renowned for his expertise in both traditional and digital art. Since embarking on his digital art journey in 2000, Siyavush has amassed a vast collection of sketches, doodles, digital pieces, designs, and 3D creations, leaving an indelible mark on the art community.",

    "As we venture deeper into the age of artificial intelligence, Siyavush has embraced the transformative power of AI, marrying it with his quarter-century-long art legacy. This platform is a testament to that evolution, showcasing a curated selection of AI-generated works inspired by his original masterpieces.",

    "With AI's unparalleled speed, the artist can produce works at a rate previously unimaginable. Therefore, art enthusiasts can look forward to an end-of-month highlight, where newly birthed pieces — a blend of age-old expertise and cutting-edge technology — will be unveiled.",

    "Drawing inspiration from a myriad of genres — including abstract art, futurism, surrealism, fantasy, sci-fi — visitors can expect an eclectic mix of styles that capture both the past and the future.",

    "We invite you to explore, admire, and even download (in low resolution) these incredible pieces, all available at no cost. However, if you choose to use any artwork from this platform, we kindly request that you attribute Siyavush Mammad as the creator. Dive into Siyavush Mammad's world, where tradition meets innovation.",
  ];

  useEffect(() => {
    document.title = "Smaiart - About";
  }, []);
  return (
    <Grid container style={{ width: "100%" }}>
      <Grid container style={{ display: "flex", flexDirection: "row" }}>
        <Grid
          item
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Image}
            style={{ width: 480 }}
            className="piece-image"
            srcSet={`${imageUrl}`}
            alt={title}
            loading="lazy"
          />
        </Grid>
        <Grid
          item
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography variant="h3">{title}</Typography>
          <Typography variant="overline">
            Digital / Traditional Artist
          </Typography>

          <Divider variant="middle" style={{ width: "100%" }} />

          {description.map((desc) => (
            <Typography variant="overline" align="left" pr={5}>
              {desc}
            </Typography>
          ))}

          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              height: "100%",
              alignItems: "flex-start",
            }}
            mt={2}
          >
            <Grid
              item
              container
              style={{
                display: "flex",
                flexDirection: "row",
              }}
              columnGap={1}
              mt={5}
            >
              {categories.map((category) => {
                return (
                  <Grid item>
                    <Chip
                      className={"chip"}
                      label={category}
                      variant="outlined"
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>

          <Grid style={{ display: "flex", columnGap: 20 }} mt={4}>
            <Link
              to="https://www.facebook.com/siyavushm"
              className="actions"
              mr={1}
              target="_blank"
              color="inherit"
            >
              <Typography variant="overline"iFacebookLogo size={26} />
            </Link>
            <Link
              to="https://www.instagram.com/siyavushm_art/"
              className="actions"
              mr={1}
              color="inherit"
              target="_blank"
              sx={{ pl: 1, pr: 1 }}
            >
              <Typography variant="overline"iInstagramLogo size={26} />
            </Link>
            <Link
              to="https://twitter.com/SiyavushMammad"
              target="_blank"
              className="actions"
              mr={1}
              color="inherit"
            >
              <Typography variant="overline"iTwitterLogo size={26} />
            </Link>
            <Link
              to="https://www.tiktok.com/@siyavush_mammad"
              target="_blank"
              className="actions"
              mr={1}
              color="inherit"
            >
              <Typography variant="overline"iTiktokLogo size={26} />
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default About;
