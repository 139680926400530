import { Routes, Navigate, Route, useLocation } from "react-router-dom";
import "./App.css";
import ResponsiveAppBar from "components/MDResponsiveAppBar";
import routes from "routes";
import MDBox from "components/MDBox";
import themeDark from "assets/theme-dark";
import theme from "assets/theme";
import { useMaterialUIController } from "context";
import { ThemeProvider } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Footer from "components/Footer";
import { store } from "./store";
import { Provider, useDispatch } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { SET_IP } from "constants";

function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;

  console.log("OK");
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <>
            <>
              <Route
                exact
                path={route.route}
                element={route.component}
                key={route.key}
              />
              <Route
                exact
                path={route.route}
                element={route.component}
                key={route.key}
              />
            </>
          </>
        );
      }

      return null;
    });

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://telegram.org/js/telegram-widget.js";

    document.head.appendChild(script);
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <Provider store={store}>
          <ToastContainer
            theme="light"
            pauseOnHover={false}
            closeOnClick
            hideProgressBar={false}
          />
          <ResponsiveAppBar />
          <MDBox>
            <Routes>
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
          </MDBox>
          <Footer />
        </Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
